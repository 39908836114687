body {
  background: #B58551;
  overflow: hidden;
}

.container {
  position: relative;
  width: 96%;
  height: 100vh;
  display: flex;
  justify-content: end;

}

img.char {
  position: absolute;
  bottom: 0;
  width: 50%
}

.foned {
  position: relative;
  top: 15%;
  display: flex;
  flex-direction: column;
  width: 44%;
}

.foned img {
  width: 55%;
  margin: 0 auto;
}

.socials {
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #F4C92D;
  border: 4px solid black;
}

.socials a {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.socials img {
  width: 50px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);

}

p {
  font-size: 24px;
  color: white;
  text-align: center;
}

@media (max-width: 600px) {
  img.char {
    width: 70%
  }

  .container {
    height: auto;
  }

  .foned {
    width: 100%;
  }

  .socials {
    top: 0px;
  }
}